import React from 'react';
import './styles/Footer.css'; // Asegúrate de que la ruta sea correcta

const Footer = () => {
  return (
    <footer className="w-full p-4 bg-black text-white text-center">
      <p>&copy; YK Estudio 2024 | Todos los derechos reservados.</p>
      <p>
        Hecho con ❤️ por{' '}
        <a
          href="https://www.linkedin.com/in/nimajnebs/"
          target="_blank"
          rel="noopener noreferrer"
          className="nimajnebs-link"
        >
          nimajnebs
        </a>
      </p>
    </footer>
  );
};

export default Footer;