import React, { useState, useCallback, memo } from 'react';
import './styles/Projects.css'; // Asegúrate de importar el archivo CSS

const projectData = [
  {
    id: 1,
    sectionId: 'proyecto-1',
    title: 'Serviteca Derco | Render para oficina HBianchi',
    description: 'Arquitecta: Karen Delgado',
    images: [
      '/images/projects/P1/1.webp',
      '/images/projects/P1/2.webp',
      '/images/projects/P1/3.webp',
      '/images/projects/P1/4.webp',
      '/images/projects/P1/5.webp',
    ],
  },
  {
    id: 2,
    sectionId: 'proyecto-2',
    title: 'Remodelación Patio interior | Render para oficina HBianchi',
    description: 'Arquitecta: Karen Delgado',
    images: [
      '/images/projects/P2/1.webp',
      '/images/projects/P2/2.webp',
      '/images/projects/P2/3.webp',
      '/images/projects/P2/4.webp',
    ],
  },
  {
    id: 3,
    sectionId: 'proyecto-3',
    title: 'Remodelación Serviteca León | Render para oficina HBianchi',
    description: 'Arquitecta: Karen Delgado',
    images: [
      '/images/projects/P3/1.webp',
      '/images/projects/P3/2.webp',
      '/images/projects/P3/3.webp',
      '/images/projects/P3/4.webp',
    ],
  },
  {
    id: 4,
    sectionId: 'proyecto-4',
    title: 'Casa RB | Diseño de vivienda unifamiliar ubicada en Lampa',
    description: 'Arquitecta: Karen Delgado',
    images: [
      '/images/projects/P4/1.webp',
      '/images/projects/P4/2.webp',
      '/images/projects/P4/3.webp',
      '/images/projects/P4/4.webp',
    ],
  },
];

const ProjectCard = memo(({ project, onProjectClick }) => (
  <div key={project.id} className="project-card mt-16">
    {project.images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt={`${project.title} ${index + 1}`}
        className="cursor-pointer w-full h-auto rounded mb-1 transition-transform duration-400 hover:scale-110"
        onClick={() => onProjectClick(project, image)}
        loading="lazy"
      />
    ))}
    <h3 className="text-xl font-semibold mt-2">{project.title}</h3>
    <p className="text-base mt-1">{project.description}</p>
  </div>
));

const ImageModal = memo(({ selectedImage, onClose }) => (
  <div
    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
    onClick={onClose}
  >
    <img src={selectedImage} alt="Selected" className="max-w-full max-h-full" />
  </div>
));


const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleProjectClick = useCallback((project, image) => {
    setSelectedProject(project);
    setSelectedImage(image);
  }, []);

  const handleCloseImage = useCallback(() => {
    setSelectedImage(null);
  }, []);
 
  return (
    <section id="projects" className="py-20">
      <h2 className="text-3xl font-bold text-center">Proyectos</h2>
      <div className="flex flex-col">
        {projectData.map((project) => (
          <section id={project.sectionId} key={project.id} className="w-full center-container">
            <ProjectCard project={project} onProjectClick={handleProjectClick} />
          </section>
        ))}
      </div>
      {selectedImage && <ImageModal selectedImage={selectedImage} onClose={handleCloseImage} />}
    </section>
  );
};

export default Projects;
