// Landing.js
import React, { useEffect, useState, useRef } from "react";
import "./styles/Landing.css";

const images = [
  "/images/projects/P1/1.jpg",
  "/images/projects/P2/1.jpg",
  "/images/projects/P3/1.jpg",
  "/images/projects/P4/1.jpg",
];

const Landing = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSectionRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="landing">
      <div className="carousel-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-slide ${
              index === currentIndex ? "active" : ""
            }`}
          >
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/images/logos/logo_bb.png"
            alt="Logo YK Estudio"
            className="mx-auto landing-image-size logo"
          />
          <button
            className="scroll-down-btn arrow"
            onClick={scrollToNextSection}
          >
            <img src="/images/logos/arrow.png" alt="Scroll down" />
          </button>
        </div>
      </div>
      <div ref={nextSectionRef}></div>
    </div>
  );
};

export default Landing;
