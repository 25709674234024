// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './styles/Navbar.css'; // Asegúrate de que la ruta sea correcta

function Navbar() {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const toggleHamburgerMenu = () => setIsHamburgerOpen(!isHamburgerOpen);

  return (
    <nav className="fixed top-0 w-full flex justify-between items-center p-4 navbar-custom z-50">
      <div className="text-white font-bold text-2xl">
        <Link to="landing" smooth={true} duration={1000}>
          <img src="/images/logos/logo_w.png" alt="" className="h-10 cursor-pointer" />
        </Link>
      </div>
      <div className="lg:flex space-x-8 mx-auto hidden lg:block">
        <Link to="services" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Servicios</Link>
        <Link to="projects" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Proyectos</Link>
        <Link to="about" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Nosotros</Link>
        <Link to="contact" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Contacto</Link>
      </div>
      <div className="lg:hidden">
        <button onClick={toggleHamburgerMenu} className="text-white focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      {isHamburgerOpen && (
        <div className="absolute top-16 right-0 w-48 bg-black text-white shadow-lg lg:hidden">
          <Link to="services" smooth={true} duration={1000} className="block px-4 py-2 hover:bg-gray-700">Servicios</Link>
          <Link to="projects" smooth={true} duration={1000} className="block px-4 py-2 hover:bg-gray-700">Proyectos</Link>
          <Link to="about" smooth={true} duration={1000} className="block px-4 py-2 hover:bg-gray-700">Nosotros</Link>
          <Link to="contact" smooth={true} duration={1000} className="block px-4 py-2 hover:bg-gray-700">Contacto</Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;