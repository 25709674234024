import './styles/Contact.css'; // Asegúrate de importar el archivo CSS

const Contact = () => {
  return (
    <section id="contact" className="text-center py-20 text-white">
      <h2 className="text-3xl font-bold mb-16">¿Interesado en nuestros servicios?</h2>
      <div className="flex justify-center items-center space-x-8">
        {/* Tarjeta de WhatsApp */}
        <div className="contact-acrylic-card text-left p-6 rounded-lg shadow-lg w-64 h-80 social-card-whatsapp">
          <h3 className="text-2xl font-semibold mb-4">WhatsApp</h3>
          <p className="mb-4">Si deseas ponerte en contacto, ¡no dudes en escribirnos!</p>
          <p className="mb-4">Cel: +56999587116</p>

          <a 
            href="https://wa.me/+56999587116" 
            className="inline-block bg-white text-green-500 px-4 py-2 rounded mb-8 flex items-center border border-green-500" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img src="/images/logos/social/whatsapp.png" alt="WhatsApp" className="h-6 w-6 mr-2" />
            Enviar Mensaje
          </a>
        </div>
        {/* Tarjeta de Redes Sociales */}
        <div className="contact-acrylic-card text-left p-6 rounded-lg shadow-lg w-64 h-80 social-card-social">
          <h3 className="text-2xl font-semibold mb-4">Redes sociales</h3>
          <p className="mb-4">Siguenos en nuestras redes sociales</p>
          <div className="flex flex-col space-y-4">
            <a 
              href="https://www.instagram.com/yk.estudio/" 
              className="text-white-500 flex items-center" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/images/logos/social/instagram.png" alt="Instagram" className="h-6 w-6 mr-2" />
              @yk.estudio
            </a>
            <a 
              href="https://web.facebook.com/people/Yk-Estudio/61559831575690/" 
              className="text-white-400 flex items-center" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/images/logos/social/facebook.png" alt="Facebook" className="h-6 w-6 mr-2" />
              YK Estudio 
            </a>
          </div>
        </div>
        {/* Tarjeta de Email */}
        <div className="contact-acrylic-card social-card-mail text-left p-6 rounded-lg shadow-lg w-64 h-80">
          <h3 className="text-2xl font-semibold mb-4">Email</h3>
          <p className="mb-4">Tambien, puedes enviarnos un correo electrónico:</p>
          <p className="mb-4">yk.arquitecto@gmail.com</p>

          
          <a 
            href="mailto:yk.arquitecto@gmail.com" 
            className="inline-block bg-white text-blue-500 px-4 py-2 rounded mb-8 flex items-center border border-blue-500" 
            target="_blank" 
            rel="noopener noreferrer"
          
          ><img src="/images/logos/social/mail.png" alt="Email" className="h-6 w-6 mr-2" />
            Enviar Correo
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;