import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./styles/Services.css";

const MediaRenderer = ({ url, title }) => {
  const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');

  if (isYouTube) {
    const videoId = url.split('v=')[1] || url.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <div className="iframe-container">
        <iframe
          width="1280"
          height="720"
          src={embedUrl}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="media-renderer"
        ></iframe>
      </div>
    );
  }

  return <img src={url} alt={title} className="media-renderer" />;
};

const ServiceCard = ({ title, imageUrl, description, cardId, expandedCard, handleToggle, subCards }) => (
  <>
    <div className="flex service-card">
      <div className="service-image-size">
        <MediaRenderer url={imageUrl} title={title} />
      </div>
      <div className="flex flex-col justify-center text-center">
        <h3 className="font-bold service-title">{title}</h3>
        <p className="service-description">{description}</p>
        <button 
          className="service-button cursor-pointer hover:opacity-75 more-details-button"
          onClick={() => handleToggle(cardId)}
        >
          Más Información
        </button>
      </div>
    </div>
    <CSSTransition
      in={expandedCard === cardId}
      timeout={500}
      classNames="service-expand"
      unmountOnExit
    >
      <div className="service-subcard">
        {subCards.map((item, index) => (
          <div
            key={item.id}
            className={`flex ${index % 2 === 1 ? "flex-row-reverse" : "flex-row"}`}
          >
            <div className="flex flex-col justify-center text-center">
              <h3 className="font-bold service-subtitle">{item.title}</h3>
              <p className="service-subdescription">{item.description}</p>
            </div>
            <div className="w-full services-subimage-size">
              <MediaRenderer url={item.imageUrl || item.videoUrl} title={item.title} />
            </div>
          </div>
        ))}
      </div>
    </CSSTransition>
  </>
);

// Componente principal Services
const Services = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleToggle = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <>
      <section id="service_design" className="mt-32">
        <h2 className=""></h2>
        <div className="flex flex-col items-center w-full">
          <ServiceCard
            title="Diseño"
            imageUrl="/images/services/design/design_sv_1024.png"
            description="Ofrecemos un servicio integral de diseño que abarca paisajismo, arquitectura e interiorismo, integrando aspectos estéticos, funcionales y técnicos para adaptarse a las necesidades específicas de cada cliente."
            cardId="service_design"
            expandedCard={expandedCard}
            handleToggle={handleToggle}
            subCards={[
              { id: 1,
                title: 'Diseño arquitectónico',
                description: 'Creamos espacios innovadores y funcionales. Desde edificaciones residenciales hasta proyectos comerciales.',
                imageUrl: '/images/services/design/d_arquitectonico_1024.png',
              },
              { id: 2,
                title: 'Diseño de interiores',
                description: 'Nuestro enfoque en el diseño de interiores combina funcionalidad y estética, seleccionando materiales, colores y mobiliario que se adapten a las preferencias de nuestros clientes.',
                imageUrl: '/images/services/design/d_interior_1024.png',
              },
              { id: 3,
                title: 'Diseño de paisaje',
                description: 'Nuestro equipo se encarga de planificar y crear jardines, áreas recreativas y paisajes sostenibles que integren flora local y soluciones ecológicas.',
                imageUrl: '/images/services/design/d_paisaje_1024.png'
              }
            ]}
          />
        </div>
      </section>

      <section id="service_render" className="mt-4">
        <h2 className=""></h2>
        <div className="flex flex-col items-center w-full">
          <ServiceCard
            title="Render"
            imageUrl="/images/services/render/render_sv_1024.png"
            description="En YK Estudio, ofrecemos un servicio de modelación 3D, creación de imágenes y animaciones realistas de proyectos arquitectónicos, ayudando a visualizar el diseño de edificios, interiores y entornos urbanos antes de su construcción."
            cardId="service_render"
            expandedCard={expandedCard}
            handleToggle={handleToggle}
            subCards={[
              { id: 3,
                title: 'Imágenes Renderizadas',
                description: 'Nuestros renders son representaciones digitales de alta calidad que muestran cada detalle del proyecto, desde la iluminación y los materiales hasta la disposición de los espacios.',
                imageUrl: '/images/services/render/custom_render.jpg' 
              },
              { id: 4,
                title: 'Video Render',
                description: 'Creamos videos que muestran los proyectos en movimiento guiando al espectador a través de cada área del proyecto, destacando características clave y elementos de diseño, y de esta forma proporcionar una experiencia envolvente.',
                imageUrl: 'https://www.youtube.com/watch?v=gwLr9luCIag'
              },
              { id: 5,
                title: 'Render 360',
                description: 'Creación de imágenes en 360 grados que permiten a los clientes explorar espacios arquitectónicos de manera inmersiva.',
                imageUrl: 'https://www.youtube.com/watch?v=3u01lGw2mWM'
              }
            ]}
          />
        </div>
      </section>

      <section id="service_management" className="mt-4">
        <h2 className=""></h2>
        <div className="flex flex-col items-center w-full">
          <ServiceCard
            title="Gestión"
            imageUrl="/images/services/managment/managment_sv_1024.png"
            description="En YK Estudio, ofrecemos un servicio integral de gestión de permisos de edificación, diseñado para facilitar a nuestros clientes el proceso de aprobación de sus proyectos. A lo largo de todo el proceso, se mantiene un contacto continuo con el cliente, ya sea para informar el estado de avance del trámite, resolver dudas, o bien, informar si llegase a ocurrir algún inconveniente durante el proceso."
            cardId="service_management"
            expandedCard={expandedCard}
            handleToggle={handleToggle}
            subCards={[
              { id: 5,
                title: 'Asesoría Inicial',
                description: 'Visita a la propiedad y evaluación del proyecto, para determinar los requisitos y permisos necesarios según la normativa vigente.',
                imageUrl: '/images/services/managment/asesoria_inicial_1024.png'
              },
              { id: 6,
                title: 'Documentación',
                description: 'Nos encargamos de recopilar y preparar toda la documentación requerida para la solicitud de permisos, asegurando que cumpla con los requisitos técnicos y legales.',
                imageUrl: '/images/services/managment/documentacion_1024.png'
              },
              {
                id: 7,
                title: 'Tramitación',
                description: 'Gestionamos el proceso de solicitud ante las autoridades competentes.',
                imageUrl: '/images/services/managment/tramitacion_1024.png'
              }
            ]}
          />
        </div>
      </section>

      <section id="service_labor" className="mt-4">
        <h2 className=""></h2>
        <div className="flex flex-col items-center w-full">
          <ServiceCard
            title="Obra"
            imageUrl="/images/services/labor/labor_sv_1024.png"
            description="En YK Estudio, ofrecemos un servicio de supervisión de obra diseñado para garantizar que el proyecto de nuestros clientes se ejecute conforme a los planos y especificaciones aprobadas, asegurando así la calidad y el cumplimiento de todas las normativas. Nuestro enfoque se centra en la coordinación efectiva entre los distintos disciplinas, el control de tiempos y la gestión de recursos."
            cardId="service_labor"
            expandedCard={expandedCard}
            handleToggle={handleToggle}
            subCards={[
              { id: 7,
                title: 'Planificación y Gestión de Proyectos',
                description: 'Elaboración de presupuestos detallados, el cual garantiza la viabilidad económica del proyecto y facilita la toma de decisiones estratégicas a lo largo de su desarrollo.',
                imageUrl: '/images/services/labor/planificacion_1024.png',
              },
              { id: 8,
                title: 'Supervisión de Obra',
                description: 'Monitoreo continuo de la construcción para asegurar que se cumplan los estándares de calidad y diseño.',
                imageUrl: '/images/services/labor/supervision_1024.png'
              },
              { id: 9,
                title: 'Asesoría en Materiales',
                description: 'Selección y recomendación de materiales que se alineen con el diseño, considerando su estética, funcionalidad, coste, mantenimiento, entre otros.',
                imageUrl: '/images/services/labor/materiales_1024.png'
              }
            ]}
          />
        </div>
      </section>
    </>
  );
};

export default Services;