// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import SocialButton from './components/SocialButton';
import Landing from './components/Landing';

const App = () => {
  return (
    <div>
      <Navbar />
      <SocialButton />
      <section id="landing">
        <Landing />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <Footer />
    </div>
  );
};

export default App;