// src/components/SocialButton.js
import React from "react";
import "./styles/SocialButton.css";

const SocialButton = () => {
  return (
    <div className="social-buttons">
      <a
        href="https://www.instagram.com/yk.estudio/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-button"
      >
        <img src="/images/logos/social/instagram.png" alt="Instagram" />
      </a>
      <a
        href="https://web.facebook.com/people/Yk-Estudio/61559831575690/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-button"
      >
        <img src="/images/logos/social/facebook.png" alt="Facebook" />
      </a>
      <a
        href="https://wa.me/+56999587116"
        target="_blank"
        rel="noopener noreferrer"
        className="social-button"
      >
        <img src="/images/logos/social/whatsapp.png" alt="WhatsApp" />
      </a>
    </div>
  );
};

export default SocialButton;
