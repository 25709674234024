// src/components/Description.js
import React from 'react';
import './styles/About.css';

const Description = () => {
  return (
    <section id="about" className="text-center py-20 text-white">
      <div className="mx-auto max-w-screen-sm px-2">
        <img src="/images/logos/logo_w.png" alt="Descripción de la imagen" className="mx-auto about-image-size" />
        <p className="mt-4 justify-text">YK, es un estudio de arquitectura dedicado a la tramitación de permisos de edificación. Nuestro equipo cuenta con un sólido conocimiento y amplia experiencia en la gestión de normativas, lo que nos permite facilitar a nuestros clientes la obtención de permisos para proyectos de diversas magnitudes y tipologías.</p>
        <p className="mt-4 justify-text">Además de nuestra experiencia en la tramitación de permisos, contamos con un equipo creativo y multidisciplinario que se dedica al diseño arquitectónico, de interiores y de paisaje. Nuestro enfoque integral nos permite ofrecer soluciones personalizadas que responden a las necesidades y deseos de nuestros clientes, garantizando un resultado estético y funcional.</p>
      </div>
    </section>
  ); 
};

export default Description;